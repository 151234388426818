import React, { useState } from 'react';
import { Provider } from 'react-redux';
import store from './store';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import Step5 from './components/Step5';
import Step6 from './components/Step6';
import Step7 from './components/Step7';

const App = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <Step1 onNext={handleNext} />;
      case 2:
        return <Step2 onNext={handleNext} />;
      case 3:
        return <Step3 onNext={handleNext} />;
      case 4:
        return <Step4 onNext={handleNext} />;
      case 5:
        return <Step5 onNext={handleNext} />;
      case 6:
        return <Step6 onNext={handleNext} />;
      case 7:
        return <Step7 />;
      default:
        return null;
    }
  };

  return (
    <Provider store={store}>
      <div className="App">

        <div className="container container-main bg-white rounded mt-4">
          <a href="https://www.8figuredreamacademy.com" className="logo">
      			<img width="64" height="64" src="https://www.8figuredreamacademy.com/wp-content/uploads/2023/08/cropped-android-chrome-512x512-1.png" className="attachment-full size-full wp-image-916" alt="" srcSet="https://www.8figuredreamacademy.com/wp-content/uploads/2023/08/cropped-android-chrome-512x512-1.png 512w, https://www.8figuredreamacademy.com/wp-content/uploads/2023/08/cropped-android-chrome-512x512-1-300x300.png 300w, https://www.8figuredreamacademy.com/wp-content/uploads/2023/08/cropped-android-chrome-512x512-1-150x150.png 150w" sizes="(max-width: 512px) 100vw, 512px" />
          </a>
          <h1 className="pt-4 generatortitle">Shop Generator</h1>
          <ul className="nav nav-tabs flex-nowrap">
            <li className={`nav-item ${currentStep === 1 ? 'active' : ''} flex-grow-1`}>
              <a className={`nav-link ${currentStep === 1 ? 'active' : ''}`} href="#step1">
                <i className="fas fa-play"></i> 1. Start
              </a>
            </li>
            <li className={`nav-item ${currentStep === 2 ? 'active' : ''} flex-grow-1`}>
              <a className={`nav-link ${currentStep === 2 ? 'active' : ''}`} href="#step2">
                <i className="fas fa-store"></i> 2. Shop eröffnen
              </a>
            </li>
            <li className={`nav-item ${currentStep === 3 ? 'active' : ''} flex-grow-1`}>
              <a className={`nav-link ${currentStep === 3 ? 'active' : ''}`} href="#step3">
                <i className="fas fa-key"></i> 3. API freigeben
              </a>
            </li>
            <li className={`nav-item ${currentStep === 4 ? 'active' : ''} flex-grow-1`}>
              <a className={`nav-link ${currentStep === 4 ? 'active' : ''}`} href="#step4">
                <i className="fas fa-check"></i> 4. Demo aktivieren
              </a>
            </li>
            <li className={`nav-item ${currentStep === 5 ? 'active' : ''} flex-grow-1`}>
              <a className={`nav-link ${currentStep === 5 ? 'active' : ''}`} href="#step5">
                <i className="fas fa-magic"></i> 5. Zaubern lassen
              </a>
            </li>
            <li className={`nav-item ${currentStep === 6 ? 'active' : ''} flex-grow-1`}>
              <a className={`nav-link ${currentStep === 6 ? 'active' : ''}`} href="#step6">
                <i className="fas fa-unlock"></i> 6. Passwort entfernen
              </a>
            </li>
            <li className={`nav-item ${currentStep === 7 ? 'active' : ''} flex-grow-1`}>
              <a className={`nav-link ${currentStep === 7 ? 'active' : ''}`} href="#step7">
                <i className="fas fa-flag-checkered"></i> 7. Fertig!
              </a>
            </li>
          </ul>

          <div className="step-content">
          {renderStep()}
          </div>
        </div>

      </div>
    </Provider>
  );
};

export default App;
