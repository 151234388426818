import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useEnterKeyToProceed from '../hooks/useEnterKeyToProceed';

const Step3 = ({ onNext }) => {
  const subdomain = useSelector((state) => state.subdomain);
  const [apiToken, setApiToken] = useState('');
  const [isValidToken, setIsValidToken] = useState(true);
  const dispatch = useDispatch();

  const handleNext = () => {
    // Validate the API token pattern
    const apiTokenPattern = /^shpat_[a-fA-F0-9]{32}$/; // Regular expression for the Shopify token pattern
    if (!apiToken.match(apiTokenPattern)) {
      setIsValidToken(false);
      return;
    }

    // If the validation succeeds, proceed
    dispatch({ type: 'SET_API_TOKEN', apiToken });
    setIsValidToken(true); // Reset validation state
    onNext();
  };

  const handleTokenChange = (e) => {
    setApiToken(e.target.value);
    setIsValidToken(true); // Reset validation state on token change
  };

  useEnterKeyToProceed(handleNext);

  return (
    <div className="p-2">
      <div className="alert alert-success">
        <i className="fas fa-check-square"></i> Deine Shopify Subdomain: <span className="code-inline">{subdomain}</span>
      </div>

      <h2>Schritt 3: API freigeben</h2>

      <div className="d-flex flex-column align-items-center mt-4">
        <p>
          Durch das Erstellen einer <b>Custom App</b> in Shopify, kannst du dem Shop Generator ermöglichen, den Shop für dich einzurichten, ohne dein Shopify Passwort zu kennen (nämlich über die sogenannte <b>Admin API</b>). Diese Methode gewährleistet einen sicheren Ablauf und beseitigt Risiken im Zusammenhang mit der Weitergabe von Passwörtern.
        </p>
        <p>Um die App auf Shopify einzurichten, befolge bitte diese Schritte:</p>

        <div className="alert alert-info step3-list mb-4">
        <ol className="list-disc text-left">
          <li>Klicke auf folgenden Link: <a rel="noopener noreferrer" href={`https://${subdomain}.myshopify.com/admin/settings/apps/development`} target="_blank"><b className="underline">Shopify-Apps öffnen</b></a>.</li>
          <li>Klicke zweimal auf <b>Benutzerdefinierte App-Entwicklung zulassen</b>.</li>
          <li>Klicke auf <b>App erstellen</b>.</li>
          <li>Fülle das Feld "Name" mit "Generator" aus.</li>
          <li>Klicke auf <b>Admin API-Berechtigungen konfigurieren</b>.</li>
          <li>Wähle ALLE API-Berechtigungen aus (wichtig für die Funktionalität).</li>
          <li>Klicke auf die Schaltfläche <b>Speichern</b> und oben rechts auf <b>Installieren</b>.</li>
          <li>Klicke einmal auf die Schaltfläche "Token anzeigen".</li>
          <li>Kopiere das angezeigte Token.</li>
          <li>Füge das Token in das untenstehende Feld ein.</li>
          <li>Klicke auf den Button "Weiter" ganz unten, um fortzufahren.</li>
        </ol>
        </div>

        <p>Denk daran, zu diesem Tab zurückzukehren, um mit der Erstellung deines Shops fortzufahren.</p>
        <div className="alert alert-warning">
          <i className="fas fa-exclamation-circle"></i><b> WICHTIG:</b> Stelle sicher, dass du <em>alle</em> Zugriffsberechtigungen auswählst. Wenn eine nicht aktiviert ist, kann der Shop-Aufbau fehlschlagen.
        </div>

    <div className="form">
      <div className="mb-3">
        <label htmlFor="adminAPIToken" className="form-label">Dein Admin API Access Token:</label>
        <input
          type="text"
          id="adminAPIToken"
          value={apiToken}
          onChange={handleTokenChange}
          className={`form-control ${!isValidToken ? 'is-invalid' : apiToken.length === 0 ? '' : 'is-valid'}`}
          placeholder="z.B. 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"
        />
        {!isValidToken && (
          <div className="invalid-feedback">Ungültiges API-Token-Format. Bitte kopiere nur das Token in das Feld.</div>
        )}
      </div>
      <button
        disabled={!isValidToken}
        onClick={handleNext}
        className="btn btn-block btn-primary mt-3">
        Weiter
      </button>
    </div>
    </div>
  </div>);
};

export default Step3;
