import React from 'react';

const Step7 = () => {
  return (
    <div className="p-2">
      <div className="d-flex flex-column align-items-center mt-4">
        <h2>Step 7: Fertig!</h2>
        <p>Großartig! Dein Shop ist einsatzbereit.</p>
        <i className="fas fa-shopping-cart fa-5x"></i>
        <p className="mt-4"><b>Tipp</b>: Mach jetzt eigene Anpassungen (z.B. deine Kontaktinformationen im Footer einfügen)!</p>
      </div>
    </div>
  );
};

export default Step7;
