import React from 'react';

import useEnterKeyToProceed from '../hooks/useEnterKeyToProceed';

const Step1 = ({ onNext }) => {
  useEnterKeyToProceed(onNext);

  return (
    <div className="text-center p-2">
      <h2 className="mb-4">Schritt 1: Start</h2>
      <h4 className="mb-2">Herzlich Willkommen!</h4>
      <p>Folge den Schritten dieses Formulars, und du wirst in wenigen Minuten einen einsatzbereiten Online-Shop besitzen!</p>
      <button className="btn btn-primary mt-4" onClick={onNext}>
        Jetzt starten
      </button>
    </div>
  );
};

export default Step1;
