import { useEffect } from 'react';

const useEnterKeyToProceed = (handleNext) => {
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleNext();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleNext]);
};

export default useEnterKeyToProceed;
