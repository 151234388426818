import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import useEnterKeyToProceed from '../hooks/useEnterKeyToProceed';

const Step5 = ({ onNext }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const subdomain = useSelector((state) => state.subdomain);
  const apiToken = useSelector((state) => state.apiToken);

  const [jobStarted, setJobStarted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [jobId, setJobId] = useState('');
  const [jobState, setJobState] = useState('');
  const [jobIsFinished, setJobIsFinished] = useState(false);
  const [progressInterval, setProgressInterval] = useState(null);
  const [errorState, setErrorState] = useState(false);

  const startJob = async () => {
    if (!jobStarted) {
      setJobStarted(true);

      try {
        const res = await fetch(`${apiUrl}/job`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ subdomain, apiToken }),
        });

        if (res.ok) {
          const job = await res.json();
          setJobId(job.id);

          const interval = setInterval(async () => {
            updateJobProgress(job.id);
          }, 1000);

          setProgressInterval(interval);
        }
      } catch (error) {
        console.error('Error starting job:', error);
        setErrorState(true);
        setJobStarted(false);
        setJobIsFinished(false);
      }
    }
  };

  const updateJobProgress = useCallback(async (inJobId) => {
    try {
      const res = await fetch(`${apiUrl}/job/${inJobId}`);
      if (res.ok) {
        const job = await res.json();
        setProgress(job.progress);
        setJobState(job.state);
        if (job.state === 'completed') {
          clearInterval(progressInterval);
          setProgressInterval(null);
          setJobIsFinished(true);
        }
      } else {
        throw new Error('Job progress check failed');
      }
    } catch (error) {
      console.error('Error checking job progress:', error);
      setErrorState(true);
      setJobStarted(false);
      setJobIsFinished(false);
      clearInterval(progressInterval);
      setProgressInterval(null);
    }
  }, [apiUrl, progressInterval]);

  const handleNext = () => {
    if (jobIsFinished) {
      onNext();
    }
  }

  useEffect(() => {
    if (jobId !== '') {
      updateJobProgress(jobId);
    }
  }, [jobId, updateJobProgress]);

  useEnterKeyToProceed(handleNext);

  return (
    <div className="p-2">
      <h2>Schritt 5: Lass nun den Generator seine Magie tun ;-)</h2>

      <div className="alert alert-success mt-4">
        <div className="mb-2"><i className="fas fa-check-square"></i> Deine Shopify Subdomain: <span className="code-inline">{subdomain}</span></div>
        <div className="mb-2"><i className="fas fa-check-square"></i> Dein API Token: <span className="code-inline">{apiToken}</span></div>
      </div>

      <div>
        <div className="progress w-100 mb-4">
          <div
            className={`progress-bar ${jobStarted ? 'bg-light-purple' : 'bg-dark-red'}`}
            role="progressbar"
            style={{ width: `${progress}%` }}
            aria-valuenow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>

        {jobState === 'active' && (
        <div className="text-center">
          <div className="spinner-border text-primary mb-2" role="status">
            <span className="visually-hidden">Ladevorgang…</span>
          </div>
          <p>Shop wird generiert, bitte warten…</p>
        </div>
        )}

        {jobIsFinished && (
        <div className="alert alert-success mt-2">
          <p>Dein Shop ist <b>fertig</b>! <br/>
          Fast. Bitte klick auf weiter oder drücke die Enter-Taste.</p>
        </div>
        )}

        {errorState && !jobStarted && (
        <div className="alert alert-danger">
          <p><b>Fehler</b>: Entschuldigung, es ist ein Fehler aufgetreten. Bitte kontaktiere uns oder versuche es noch einmal.</p>
        </div>
        )}

      </div>

      <div className="d-flex flex-column align-items-center mt-4">
        {jobStarted && !jobIsFinished && (
          <div className="alert alert-info">
            <i className="fas fa-info-circle"></i> Es kann ein paar Minuten dauern. Bitte schließe dieses Fenster nicht!<br/>
            Sobald dein Shop fertig generiert ist, kannst du auf den Weiter-Button drücken.
          </div>
        )}

        <div className="form">
          <div className="buttons-container align-items-center">
          {!jobIsFinished && (<button
             disabled={jobStarted}
             className="btn btn-block btn-success mt-3"
             onClick={startJob}
           >
             {jobStarted ? 'Generator läuft…' : 'Starte den Generator!'}
           </button>
         )}
            <button
               disabled={!jobIsFinished}
               className="btn btn-block btn-primary mt-3"
               onClick={handleNext}
             >
              {jobIsFinished ? 'Weiter' : 'Weiter (anklickbar sobald fertig)'}
             </button>
           </div>
        </div>
      </div>
    </div>
  );
};

export default Step5;
