import React from 'react';
import { useSelector } from 'react-redux';

const Step6 = ({ onNext }) => {

  const subdomain = useSelector((state) => state.subdomain);

  const openOnlineStoreSettingsLink = () => {
    window.open(`https://admin.shopify.com/store/${subdomain}/online_store/preferences`, '_blank');
  }

  const handleNext = () => {
    onNext();
  };

  return (
    <div className="p-2">
      <h2>Schritt 6: Entferne das Passwort von deinem Online Shop</h2>
      <p>Damit Kunden deinen Shop sehen können, musst du noch den Passwortschutz in den "Online Store"-Einstellungen deaktiveren.</p>

      <div className="d-flex flex-column align-items-center mt-4">
        <div className="form">
          <div className="buttons-container align-items-center">
            <button className="btn btn-block btn-success mt-3" onClick={openOnlineStoreSettingsLink}>Zu den Shopify-Plänen</button>
            <button
               className="btn btn-block btn-primary mt-3"
               onClick={handleNext}
             >
               Weiter
              </button>
           </div>
        </div>
      </div>
    </div>
  );
};

export default Step6;
