import React from 'react';
import { useSelector } from 'react-redux';

import useEnterKeyToProceed from '../hooks/useEnterKeyToProceed';

const Step4 = ({ onNext }) => {
  const subdomain = useSelector((state) => state.subdomain);
  const apiToken = useSelector((state) => state.apiToken);

  const openPlanLink = () => {
    window.open(`https://admin.shopify.com/store/${subdomain}/settings/plan`, '_blank');
  }

  const handleNext = () => {
    // Call the onNext callback to proceed to the next step
    onNext();
  };

  useEnterKeyToProceed(handleNext);

  return (
    <div className="p-2">
      <div className="alert alert-success">
        <div className="mb-2"><i className="fas fa-check-square"></i> Deine Shopify Subdomain: <span className="code-inline">{subdomain}</span></div>
        <div className="mb-2"><i className="fas fa-check-square"></i> Dein API Token: <span className="code-inline">{apiToken}</span></div>
      </div>

      <h2>Schritt 4: Demo aktivieren</h2>
      <p>Bitte folge diesen Schritten, um deinen Shopify-Shop Demo zu aktivieren um Einschränkungen zu vermeiden und alle Features freizuschalten:</p>

      <div className="d-flex flex-column align-items-center mt-4">
        <div className="alert alert-info">
          <ol>
            <li>Klicke auf den grünen Button unten, um zur Shopify-Plan-Übersicht zu gelangen.</li>
            <li>Wähle den monatlichen "Basic"-Plan aus.</li>
            <li>Gib deinen Namen, deine Geschäftsadresse usw. ein.</li>
            <li>Wähle deine Zahlungsmethode aus.</li>
            <li>Gib deine Infos für die gewählte Zahlungsmethode ein.</li>
          </ol>
        </div>

        <div className="form">
          <div className="buttons-container align-items-center">
            <button className="btn btn-block btn-success mt-3" onClick={openPlanLink}>Zu den Shopify-Plänen</button>
            <button
               className="btn btn-block btn-primary mt-3"
               onClick={handleNext}
             >
               Weiter
             </button>
           </div>
         </div>
      </div>
    </div>
  );
};

export default Step4;
