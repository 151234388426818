import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import useEnterKeyToProceed from '../hooks/useEnterKeyToProceed';

const Step2 = ({ onNext }) => {
  const [subdomain, setSubdomain] = useState('');
  const [isValid, setIsValid] = useState(false);
  const dispatch = useDispatch();

  const openRefLink = () => {
    window.open('https://shopify.pxf.io/c/3934646/1808652/13624', '_blank');
  };

  const handleNext = () => {
    if (isValid) {
      dispatch({ type: 'SET_SUBDOMAIN', subdomain });
      onNext();
    } else {
      console.log('Invalid subdomain. Please enter a valid subdomain.');
    }
  };

  const validateSubdomain = (value) => {
    const subdomainRegex = /^[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$/;
    const isValidSubdomain = subdomainRegex.test(value);
    setIsValid(isValidSubdomain);
  };

  const handleSubdomainChange = (e) => {
    const { value } = e.target;
    setSubdomain(value);
    validateSubdomain(value);
  };

  useEnterKeyToProceed(handleNext);

  return (
   <div className="p-2">
     <div className="container text-center">
       <h2 className="mb-2">Schritt 2: Shop eröffnen</h2>

       <div className="d-flex flex-column align-items-center mt-4">

           <p>Bevor wir mit dem Erstellen deines Shops beginnen, musst du zuerst ein Shopify-Konto erstellen.</p>
           <div className="alert alert-info">
             <ol className="list-disc text-left ml-6">
               <li>Klick auf die Schaltfläche <b className="underline">Konto erstellen</b> unten.</li>
               <li>Füll das Registrierungsformular aus, um dich anzumelden.</li>
               <li>Kehre zu diesem Tab zurück und füge die URL deines Shops unten ein (bzw. die Subdomain am Anfang der URL).</li>
               <li>Klicke auf den "Weiter"-Button ganz untern.</li>
             </ol>
           </div>

        <div className="form">
          <label className="mt-3">Deine Shopify Subdomain:</label>
          <input
            type="text"
            value={subdomain}
            onChange={handleSubdomainChange}
            className={`form-control ${isValid ? 'is-valid' : 'is-invalid'}`}
            />

         <div className="buttons-container align-items-center">
           <button className="btn btn-block btn-success mt-3" onClick={openRefLink}>Konto erstellen</button>
           <button
              disabled={!isValid}
              className="btn btn-block btn-primary mt-3"
              onClick={handleNext}
            >
              Weiter
            </button>
          </div>
         </div>
       </div>
     </div>
   </div>
 );
};

export default Step2;
